
































































































































































































import { defineComponent, ref, PropType, watch } from "@vue/composition-api";
import { subYears, startOfDay, getYear } from "date-fns";

import store from "@/store";

import InfoPopup from "@/shared/components/InfoPopup";

import RegistrationStepContent from "../../../RegistrationStepContent";
import { RegistrationStepState } from "../../../RegistrationStep";
import RegistrationStepForm from "../../../RegistrationStepForm";
import RegistrationSalutations from "../../../RegistrationSalutations";
import RegistrationBranchTypes from "../../../RegistrationBranchTypes";

import RegistrationPersonalDetailsStepOverview from "./RegistrationPersonalDetailsStepOverview.vue";
import { CustomerType } from "@/api/sap";

export default defineComponent({
  name: "RegistrationPersonalDetailsStep",
  components: {
    InfoPopup,
    RegistrationStepContent,
    RegistrationStepForm,
    RegistrationSalutations,
    RegistrationBranchTypes,
    RegistrationPersonalDetailsStepOverview,
  },
  props: {
    state: {
      type: String as PropType<RegistrationStepState>,
      default: RegistrationStepState.unvisited,
    },
  },
  setup(props) {
    const minBirthdate = subYears(startOfDay(new Date()), 100);
    const maxBirthdate = subYears(startOfDay(new Date()), 18);
    const datepickerRangeErrors = ref<string[]>([]);
    const visibleYears = getYear(maxBirthdate) - getYear(minBirthdate);

    const isBusinessCustomer = ref(
      store.getters.registrationRouteIsBusinessCustomer
    );

    const formData = ref({
      ...store.getters.registrationPersonalDetailsFormData,
    });

    watch(
      () => props.state,
      (currentState, prevState) => {
        if (
          currentState !== prevState &&
          prevState === RegistrationStepState.visited
        ) {
          formData.value = {
            ...store.getters.registrationPersonalDetailsFormData,
          };
        }
      }
    );

    function onSubmit() {
      store.commit.setPersonalDetails(formData.value);
    }

    return {
      formData,
      minBirthdate,
      maxBirthdate,
      visibleYears,
      datepickerRangeErrors,
      CustomerType,
      isBusinessCustomer,
      onSubmit,
    };
  },
});
