








































































































import {
  defineComponent,
  computed,
  watchEffect,
  watch,
  reactive,
  toRef,
  toRefs,
  PropType,
  onMounted,
  ref,
} from "@vue/composition-api";

import {
  DivisionType,
  HeatingType,
  CustomerType,
  GetAddressesReturnType,
} from "@/api/sap";

import store from "@/store";

import {
  useConsumptionSliderOptions,
  useInitTariffCalc,
  useTariffCalcSubmit,
} from "@/features/tariff-calculator/hooks";

import SapPostalCodeField from "@/features/shared/SapPostalCodeField";

import { TariffCalculatorConfig } from "@/features/tariff-calculator/components/TariffCalculator";
import TariffCalculatorBlockingTime from "@/features/tariff-calculator/components/TariffCalculatorBlockingTime";
import TariffCalculatorConsumptionSlider from "@/features/tariff-calculator/components/TariffCalculatorConsumptionSlider";
import TariffCalculatorCustomerType from "@/features/tariff-calculator/components/TariffCalculatorCustomerType";
import TariffCalculatorDCM from "@/features/tariff-calculator/components/TariffCalculatorDCM";
import TariffCalculatorForm from "@/features/tariff-calculator/components/TariffCalculatorForm";
import TariffCalculatorHCM from "@/features/tariff-calculator/components/TariffCalculatorHCM";
import TariffCalculatorHeatingTypeSelect from "@/features/tariff-calculator/components/TariffCalculatorHeatingTypeSelect";
import TariffCalculatorInfoBox from "@/features/tariff-calculator/components/TariffCalculatorInfoBox";
import { pushEvent } from "@/helper/pushEvent.helper";

interface TariffCalculatorHeatingViewState {
  affiliateId?: string;
  calculatorMode: keyof typeof DivisionType;
  customerType: CustomerType;
  address: GetAddressesReturnType | undefined;
  powerConsumption: string | undefined;
  hcmAvailable: string | undefined;
  isDoubleTariffMeter: boolean | undefined;
  highTariff: string | undefined;
  lowTariff: string | undefined;
  isBlockingTime: boolean | undefined;
  heatingType: HeatingType | undefined;
  isInOberhausen: boolean | undefined;
  allowedProductIds?: string;
  serviceNumber?: string;
}

export default defineComponent({
  name: "TariffCalculatorHeatingView",
  components: {
    SapPostalCodeField,
    TariffCalculatorConsumptionSlider,
    TariffCalculatorCustomerType,
    TariffCalculatorDCM,
    TariffCalculatorForm,
    TariffCalculatorHCM,
    TariffCalculatorHeatingTypeSelect,
    TariffCalculatorInfoBox,
    TariffCalculatorBlockingTime,
  },
  props: {
    config: {
      type: Object as PropType<TariffCalculatorConfig>,
      default: () => ({} as TariffCalculatorConfig),
    },
    initialValues: {
      type: Object as PropType<Partial<TariffCalculatorHeatingViewState>>,
      default: () => ({} as Partial<TariffCalculatorHeatingViewState>),
    },
    shouldRedirect: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const isSingleCustomerType = ref(false);
    const isCalculatorTypeMini = ref(false);

    onMounted(() => {
      if (props.config.customerType) {
        isSingleCustomerType.value = true;
      }

      if (props.config.calculatorMini) {
        isCalculatorTypeMini.value = props.config.calculatorMini;
      }
    });

    const defaultState: TariffCalculatorHeatingViewState = {
      calculatorMode: "Heating",
      customerType: (props.config.customerType as keyof typeof CustomerType)
        ? CustomerType[props.config.customerType as keyof typeof CustomerType]
        : CustomerType.PrivateCustomer,
      address: undefined,
      powerConsumption: undefined,
      hcmAvailable: undefined,
      isDoubleTariffMeter: false,
      highTariff: undefined,
      lowTariff: undefined,
      isBlockingTime: undefined,
      heatingType: undefined,
      isInOberhausen: true,
      allowedProductIds: props.config.allowedProductIds,
    };

    const { getInitialStateValues } = useInitTariffCalc(
      defaultState,
      props.initialValues
    );

    const state = reactive<TariffCalculatorHeatingViewState>({
      ...defaultState,
      ...getInitialStateValues(),
    });

    const { submitCalculator } = useTariffCalcSubmit(
      computed(() => props.config.resultURL),
      props.shouldRedirect
    );

    const {
      sliderOptions,
      fillIconItems,
      iconName,
    } = useConsumptionSliderOptions({
      mode: toRef(state, "calculatorMode"),
      customerType: toRef(state, "customerType"),
    });

    const isNightStorage = computed(
      () => state.heatingType === HeatingType.NightStorage
    );
    const isHeatingPump = computed(
      () => state.heatingType === HeatingType.HeatingPump
    );

    const hasHcm = computed(() => {
      return state.hcmAvailable == "1";
    });

    const isDtm = computed(() => state.isDoubleTariffMeter == true);

    const isInOberhausen = computed(() => state.address?.OBERHAUSEN == "X");

    const showDoubleTariffMeterForNightStorage = computed(
      () => isNightStorage.value && state.hcmAvailable !== undefined
    );

    const showDoubleTariffMeterForHeatingPump = computed(
      () =>
        isHeatingPump.value &&
        hasHcm.value &&
        state.isInOberhausen !== undefined &&
        !isInOberhausen.value &&
        isDtm.value
    );

    const showDoubleTariffMeter = computed(
      () =>
        showDoubleTariffMeterForNightStorage.value ||
        showDoubleTariffMeterForHeatingPump.value
    );

    const showSingleTariffMeterForNoHCM = computed(
      () =>
        isHeatingPump.value && state.hcmAvailable !== undefined && !hasHcm.value
    );

    const showSingleTariffMeterForHCM = computed(
      () =>
        isHeatingPump.value &&
        hasHcm.value &&
        (isInOberhausen.value ||
          (state.isInOberhausen !== undefined &&
            !isInOberhausen.value &&
            state.isDoubleTariffMeter !== undefined &&
            !isDtm.value))
    );

    const showSingleTariffMeter = computed(
      () =>
        showSingleTariffMeterForHCM.value || showSingleTariffMeterForNoHCM.value
    );

    const showHCMInfoBox = computed(() => showSingleTariffMeterForNoHCM.value);

    const showIsDCM = computed(
      () =>
        isHeatingPump.value &&
        hasHcm.value &&
        state.isInOberhausen !== undefined &&
        !isInOberhausen.value
    );

    const showIsBlockingTime = computed(
      () => isHeatingPump.value && hasHcm.value && isInOberhausen.value
    );

    const formIsDisabled = computed(
      () => showHCMInfoBox.value || state.hcmAvailable === undefined
    );

    const gridClasses = computed(() => {
      if (isCalculatorTypeMini.value) {
        return "lg:grid-cols-2 mt-4 lg:mt-8";
      } else if (isSingleCustomerType.value) {
        return "lg:grid-cols-3";
      }

      return "lg:grid-cols-4";
    });

    const hasElectricityCalculator = computed(
      () => store.getters.hasElectricityCalculator
    );

    watch(
      () => state.customerType,
      (currentValue, prevHeatingType) => {
        if (currentValue === prevHeatingType) {
          return;
        }

        if (
          currentValue === CustomerType.BusinessCustomer &&
          state.heatingType !== HeatingType.HeatingPump
        ) {
          state.heatingType = undefined;
        }
      }
    );

    watch(
      () => state.heatingType,
      (currentValue, prevHeatingType) => {
        if (currentValue === prevHeatingType) {
          return;
        }

        state.hcmAvailable = undefined;
        state.highTariff = undefined;
        state.lowTariff = undefined;
      }
    );

    watch(showSingleTariffMeter, (currentValue, prevHeatingType) => {
      if (currentValue === prevHeatingType) {
        return;
      }

      state.highTariff = undefined;
      state.lowTariff = undefined;
    });

    watch(showDoubleTariffMeter, (currentValue, prevHeatingType) => {
      if (currentValue === prevHeatingType) {
        return;
      }

      state.powerConsumption = undefined;
    });

    watch(
      () => state.isInOberhausen,
      (currentValue, prevHeatingType) => {
        if (currentValue === prevHeatingType) {
          return;
        }

        state.isBlockingTime = undefined;
        state.isDoubleTariffMeter = undefined;
      }
    );

    watchEffect(() => {
      if (state.hcmAvailable !== undefined) {
        return;
      }
    });

    watch(
      () => state.address,
      (address, prevAddress) => {
        if (address === prevAddress) {
          return;
        }

        state.hcmAvailable = undefined;
        state.highTariff = undefined;
        state.lowTariff = undefined;
        state.isBlockingTime = undefined;
        state.isDoubleTariffMeter = false;
        state.powerConsumption = undefined;
      }
    );

    function changeActiveTab() {
      store.commit.setCurrentMode("Electricity");
    }

    async function onSubmit() {
      pushEvent({
        event: "calculate",
        eventCategory: "Tarifrechner",
        eventAction: "Tarif berechnen",
        eventLabel: "Heizstrom",
      });

      submitCalculator({ values: state });
    }

    return {
      ...toRefs(state),
      state,
      fillIconItems,
      HeatingType,
      onSubmit,
      showSingleTariffMeter,
      showDoubleTariffMeter,
      showHCMInfoBox,
      showIsBlockingTime,
      showIsDCM,
      sliderOptions,
      iconName,
      isSingleCustomerType,
      changeActiveTab,
      formIsDisabled,
      isHeatingPump,
      isCalculatorTypeMini,
      gridClasses,
      hasElectricityCalculator,
    };
  },
});
