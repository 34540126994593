




























import { defineComponent, PropType, computed } from "@vue/composition-api";

import LoadingOverlay from "@/shared/components/LoadingOverlay";

export default defineComponent({
  name: "AsyncInputField",
  inheritAttrs: false,
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    handleRetry: {
      type: Function as PropType<() => void>,
      default: undefined,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LoadingOverlay,
  },
  setup(props) {
    const canRetry = computed(() => props.handleRetry && !props.loading);
    const shouldRetry = computed(() => canRetry && props.hasError);
    const showRetry = computed(() => shouldRetry.value);

    function retry() {
      if (shouldRetry.value) {
        props.handleRetry();
      }
    }

    return {
      shouldRetry,
      showRetry,
      retry,
    };
  },
});
