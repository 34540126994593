








import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
  name: "ExtendButton",
  props: {
    title: {
      type: String,
    },
    extended: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const iconName = computed(() => (props.extended ? "Minus" : "Plus"));

    return { iconName };
  },
});
