import { computed, Ref } from "@vue/composition-api";
import { get } from "lodash-es";

import { DivisionType } from "@/api/sap";
import featureConfig from "@/features/tariff-calculator/config";

interface UseConsumptionSliderOptions {
  mode: Ref<keyof typeof DivisionType>;
  customerType: Ref<string | number>;
}

export function useConsumptionSliderOptions({
  mode,
  customerType,
}: UseConsumptionSliderOptions) {
  const config = computed(() =>
    get(featureConfig.powerConsumption, `${customerType.value}.${mode.value}`)
  );

  const sliderOptions = computed(() => get(config.value, "sliderOptions"));

  const steps = computed<number[]>(() => get(config.value, "steps", []));

  const iconName = computed<string>(() =>
    get(sliderOptions.value, "iconName", "")
  );

  const computedIconPathNames = computed<string[]>(() =>
    get(sliderOptions.value, "iconPathNames", [])
  );

  const fillIconItems = computed(() =>
    computedIconPathNames.value.map((id, index) => ({
      id,
      value: get(steps.value, index, featureConfig.defaults.sliderOptions.max),
    }))
  );

  return {
    sliderOptions,
    steps,
    fillIconItems,
    iconName,
  };
}
