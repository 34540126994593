import { compact } from "lodash-es";

import {
  ReadProductsPostData,
  DivisionType,
  CustomerType,
  GetAddressesReturnType,
  HeatingType,
} from "@/api/sap";

import store from "@/store";

import { useWindowUtils } from "@/hooks/useWindowUtils.hook";
import { ComputedRef } from "@vue/composition-api";

interface SharedValues {
  calculatorMode: keyof typeof DivisionType;
  customerType: CustomerType;
  address: GetAddressesReturnType | undefined;
  allowedProductIds?: string;
  promotionCode?: string;
}

interface ElectricityValues extends SharedValues {
  powerConsumption: string | undefined;
}

interface GasValues extends SharedValues {
  gasConsumption: string | undefined;
}

interface HeatingValues extends SharedValues {
  powerConsumption: string | undefined;
  hcmAvailable: string | undefined;
  isDoubleTariffMeter: boolean | undefined;
  highTariff: string | undefined;
  lowTariff: string | undefined;
  isBlockingTime: string | undefined;
  heatingType: HeatingType | undefined;
  isInOberhausen: boolean | undefined;
}

export type SubmitValues = ElectricityValues | GasValues | HeatingValues;

export function useTariffCalcSubmit(
  resultsURL?: ComputedRef<string | undefined>,
  shouldRedirect = false
) {
  const { redirect } = useWindowUtils();
  const DIST_CHANNEL = "1";

  function getElectricityCalculatorPayload(values: ElectricityValues) {
    const payload: ReadProductsPostData = {
      ZZ_DIST_CHANNEL: DIST_CHANNEL,
      BU_TYPE: values.customerType,
      CITY: values.address?.LINE || "",
      POST_CODE: values.address?.POST_CODE1 || "",
      ALLOWED_PRODUCT_IDS: values.allowedProductIds || "",
      CONSMPT_SPART: [
        {
          ISS_CONSMPT_SPART: [
            {
              SPARTE: DivisionType.Electricity,
              CONSMPT: String(values.powerConsumption),
            },
          ],
        },
      ],
    };

    return payload;
  }

  function getGasCalculatorPayload(values: GasValues) {
    const payload: ReadProductsPostData = {
      ZZ_DIST_CHANNEL: DIST_CHANNEL,
      BU_TYPE: values.customerType,
      CITY: values.address?.LINE || "",
      POST_CODE: values.address?.POST_CODE1 || "",
      ALLOWED_PRODUCT_IDS: values.allowedProductIds || "",
      CONSMPT_SPART: [
        {
          ISS_CONSMPT_SPART: [
            {
              SPARTE: DivisionType.Gas,
              CONSMPT: String(values.gasConsumption),
            },
          ],
        },
      ],
    };

    return payload;
  }

  function getHeatingCalculatorPayload(values: HeatingValues) {
    const sharedReqData = {
      SPARTE: DivisionType.Heating,
      ANLART: values.heatingType,
    };

    const ISS_CONSMPT_SPART = compact([
      values.highTariff && {
        ...sharedReqData,
        CONSMPT: String(values.highTariff),
        ZWART: "HT",
      },
      values.lowTariff && {
        ...sharedReqData,
        CONSMPT: String(values.lowTariff),
        ZWART: "NT",
      },
      values.powerConsumption && {
        ...sharedReqData,
        CONSMPT: String(values.powerConsumption),
      },
    ]);

    const payload: ReadProductsPostData = {
      ZZ_DIST_CHANNEL: DIST_CHANNEL,
      BU_TYPE: values.customerType,
      CITY: values.address?.LINE || "",
      POST_CODE: values.address?.POST_CODE1 || "",
      ALLOWED_PRODUCT_IDS: values.allowedProductIds || "",
      ...(values.hcmAvailable == "1" && { HAS_SEPARATE_METER: "X" }),
      ...(values.isBlockingTime == "1" && { HAS_SHUTOFF_TIMES: "X" }),
      CONSMPT_SPART: [
        {
          ISS_CONSMPT_SPART,
        },
      ],
    };

    return payload;
  }

  async function submitCalculator(props: {
    calculatorType?: keyof typeof DivisionType;
    values: SubmitValues;
  }) {
    const calculatorType = props.values.calculatorMode ?? props.calculatorType;
    const promotionCode = store.state.tariffCalculator.config.promotionCode;

    if (!calculatorType) {
      return;
    }

    if (shouldRedirect) {
      const values = {
        ...props.values,
        ...(!!promotionCode && { promotionCode }),
      };

      return redirect({
        resultsURL: resultsURL?.value,
        values,
      });
    }

    const payloadGetters = {
      Electricity: getElectricityCalculatorPayload,
      Gas: getGasCalculatorPayload,
      Heating: getHeatingCalculatorPayload,
    };

    // eslint-disable-next-line
    const payload = payloadGetters[calculatorType](props.values as any);

    return store.dispatch.fetchProducts({
      payload,
      lastRequestData: props.values,
    });
  }

  return {
    submitCalculator,
  };
}
