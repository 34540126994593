var render = function (_h,_vm) {var _c=_vm._c;return _c('div',{staticClass:"border",class:[
    _vm.data.class,
    _vm.data.staticClass,
    {
      'border-red-500 text-red-500': _vm.props.hasError,
      'border-neon-blue-500 text-neon-blue-500': !_vm.props.hasError,
    } ]},[_c('div',{staticClass:"relative px-5 py-4 pl-12"},[_c('div',{staticClass:"absolute top-0 left-0 mt-3 ml-3"},[_c('BaseIcon',{staticClass:"text-current w-7 h-7",attrs:{"name":"Warning"}})],1),_c('p',{class:{
        'text-red-500': _vm.props.hasError,
        'text-gray-900': !_vm.props.hasError,
      }},[_vm._t("default")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }