














import { defineComponent } from "@vue/composition-api";

// import store from "@/store";

import TariffCalculator from "./features/tariff-calculator";
import RegistrationRoute from "./features/registration-route";
import FundingProjects from "./features/funding-projects";

export default defineComponent({
  name: "Prototype",
  components: {
    TariffCalculator,
    RegistrationRoute,
    FundingProjects,
  },
  setup() {
    return {};
  },
});
