


























































import { get } from "lodash-es";

import { GetProductType } from "@/features/tariff-calculator/state/getters";
import {
  getSelectableProductCombiOptions,
  isOptionBlacklisted,
} from "@/api/sap/helper/product-options.helper";

import { defineComponent, PropType, computed, ref } from "@vue/composition-api";
import TariffCalculatorProductOption from "@/features/tariff-calculator/components/TariffCalculatorProductOption";
import TariffCalculatorProductOptionInfoPopup from "@/features/tariff-calculator/components/TariffCalculatorProductOptionInfoPopup";

export default defineComponent({
  name: "TariffCalculatorProductOptions",
  props: {
    productData: {
      type: Object as PropType<GetProductType>,
      required: true,
    },
    featured: {
      type: Boolean,
      default: true,
    },
    hasBadge: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
    },
  },
  components: {
    TariffCalculatorProductOption,
    TariffCalculatorProductOptionInfoPopup,
  },
  setup(props) {
    const productOptionFetching = ref(false);

    const selectableCombinedProductOptions = computed(() =>
      getSelectableProductCombiOptions(props.productData)
    );

    const shouldRenderProductOption = (type: string) => {
      return !isOptionBlacklisted(type);
    };

    return {
      get,
      productOptionFetching,
      selectableCombinedProductOptions,
      shouldRenderProductOption,
    };
  },
});
