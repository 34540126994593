<template functional>
  <h4 :class="['text-center uppercase', data.class, data.staticClass]">
    {{ props.heading }}
  </h4>
</template>

<script>
export default {
  name: "TariffCalculatorHeading",
  props: {
    heading: {
      type: String,
      required: true,
    },
  },
};
</script>
