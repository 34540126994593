





























import { defineComponent } from "@vue/composition-api";

import InfoPopup from "@/shared/components/InfoPopup";

import { BaseRadioButtonProps } from "@/shared/components/base/BaseRadioButton";

export default defineComponent({
  name: "TariffCalculatorDCM",
  components: {
    InfoPopup,
  },
  setup() {
    const options: BaseRadioButtonProps[] = [
      {
        name: "isDoubleTariffMeter",
        value: 1,
        title: "Ja",
      },
      {
        name: "isDoubleTariffMeter",
        value: 0,
        title: "Nein",
      },
    ];

    return {
      options,
    };
  },
});
