<template functional>
  <div
    class="border"
    :class="[
      data.class,
      data.staticClass,
      {
        'border-red-500 text-red-500': props.hasError,
        'border-neon-blue-500 text-neon-blue-500': !props.hasError,
      },
    ]"
  >
    <div class="relative px-5 py-4 pl-12">
      <div class="absolute top-0 left-0 mt-3 ml-3">
        <BaseIcon class="text-current w-7 h-7" name="Warning" />
      </div>
      <p
        :class="{
          'text-red-500': props.hasError,
          'text-gray-900': !props.hasError,
        }"
      >
        <slot />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TariffCalculatorInfoBox",
  props: {
    hasError: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
