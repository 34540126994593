import { nanoid } from "nanoid";
import { FundingProjectItem } from "./types";

export const defaultCategories = [
  {
    title: "alle anzeigen",
    value: "",
    name: "all",
  },
];

export const defaultAdditionalProjects: FundingProjectItem[] = [
  {
    id: nanoid(),
    affiliateId: "",
    serviceNo: {},
    categories: "",
    hasDetailView: false,
    showAlways: true,
    grid: {
      image: {} as any,
      title: "Kein Projekt fördern",
      subTitle: "Ihre Kosten verringern sich nicht.",
    },
    detail: {} as any,
  },
];
