






























































import {
  defineComponent,
  PropType,
  computed,
  onMounted,
} from "@vue/composition-api";

import store from "@/store";

import TariffCalculatorProductDetailsPrices from "@/features/tariff-calculator/components/TariffCalculatorProductDetailsPrices";
import TariffCalculatorProductDetailsBulkPrices from "@/features/tariff-calculator/components/TariffCalculatorProductDetailsBulkPrices";
import TariffCalculatorProductDetailsContract from "@/features/tariff-calculator/components/TariffCalculatorProductDetailsContract";
import TariffCalculatorPriceInfo from "@/features/tariff-calculator/components/TariffCalculatorPriceInfo";
import { getTariffConsumption } from "@/features/tariff-calculator/components/TariffCalculatorProductDetails/TariffCalculatorProductDetails.vue";

import RegistrationStepContent from "../../../RegistrationStepContent";
import RegistrationStepSubmit from "../../../RegistrationStepSubmit";
import { RegistrationStepState } from "../../../RegistrationStep";
import RegistrationStepForm from "../../../RegistrationStepForm";
import { get, isEmpty } from "lodash-es";
import { pushEvent } from "@/helper/pushEvent.helper";
import { formatNumber } from "@/helper";

export default defineComponent({
  name: "RegistrationTariffStep",
  props: {
    state: {
      type: String as PropType<RegistrationStepState>,
      default: RegistrationStepState.unvisited,
    },
    handleNextStep: {
      type: Function as PropType<() => void>,
      default: () => undefined,
    },
  },
  components: {
    TariffCalculatorProductDetailsPrices,
    TariffCalculatorProductDetailsBulkPrices,
    TariffCalculatorProductDetailsContract,
    TariffCalculatorPriceInfo,
    RegistrationStepSubmit,
    RegistrationStepContent,
    RegistrationStepForm,
  },
  setup(props) {
    const productData = computed(
      () =>
        store.state.registrationRoute.formData.registrationTariffStep.product
    );

    const hasProductData = computed(() => !isEmpty(productData.value));

    const isBuildingKitTariff = computed(
      () => !!productData.value?.productOptions?.length
    );

    const tariffConsumption = productData.value
      ? getTariffConsumption(productData.value)
      : null;

    onMounted(() => {
      if (hasProductData && props.state === "visited") {
        pushEvent({
          event: "EEcheckout",
          ecommerce: {
            checkout: {
              actionField: {
                step: store.getters.currentRegistrationStep,
              },
              products: [
                {
                  name: productData.value?.name,
                  id: productData.value?.id,
                  price: formatNumber(
                    get(productData.value?.prices, [
                      "annualCosts",
                      "0",
                      productData.value?.priceUnit,
                    ])
                  ),
                  brand: "EVO",
                  category: productData.value?.calculatorMode,
                  quantity: 1,
                },
              ],
            },
          },
        });
      }
    });

    return {
      productData,
      hasProductData,
      isBuildingKitTariff,
      tariffConsumption,
    };
  },
});
