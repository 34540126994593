<template>
  <div class="container">
    <TariffCalculatorHeading
      v-if="heading"
      :heading="heading"
      :class="isCalculatorMini ? 'mb-2 lg:mb-4' : 'mb-7 lg:mb-15'"
    />

    <ValidationObserver v-slot="{ invalid, handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <input type="hidden" autocomplete="false" />

        <slot />

        <TariffCalculatorFormSubmit :invalid="invalid" :disabled="disabled" />
      </form>
    </ValidationObserver>

    <TariffCalculatorErrors />
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import { ValidationObserver } from "vee-validate";

import TariffCalculatorHeading from "@/features/tariff-calculator/components/TariffCalculatorHeading";
import TariffCalculatorFormSubmit from "@/features/tariff-calculator/components/TariffCalculatorFormSubmit";
import TariffCalculatorErrors from "@/features/tariff-calculator/components/TariffCalculatorErrors";

export default defineComponent({
  name: "TariffCalculatorForm",
  props: {
    heading: {
      type: String,
    },
    onSubmit: {
      type: Function,
      required: true,
    },
    isCalculatorMini: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ValidationObserver,
    TariffCalculatorHeading,
    TariffCalculatorFormSubmit,
    TariffCalculatorErrors,
  },
});
</script>
