





















































import {
  defineComponent,
  reactive,
  PropType,
  watch,
  computed,
  ref,
} from "@vue/composition-api";

import { BaseRadioButtonProps } from "@/shared/components/base/BaseRadioButton";

import { defaultCategories } from "../constants";
import { transformCategories } from "../helper";

export default defineComponent({
  name: "FundingProjectsFilter",
  props: {
    categories: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const initialShowFilter = window.innerWidth < 1024 ? false : true;
    const allCategories = computed<BaseRadioButtonProps[]>(() => [
      ...defaultCategories,
      ...transformCategories(props.categories),
    ]);

    const searchRef = ref<HTMLInputElement>();

    const state = reactive({
      showFilter: initialShowFilter,
      category: "",
      searchText: "",
    });

    function focusSearchInput() {
      searchRef.value?.focus();
    }

    watch(
      () => state.category,
      (current, prev) => {
        if (current !== prev) {
          emit("update:category", state.category);
        }
      }
    );

    watch(
      () => state.searchText,
      (current, prev) => {
        if (current !== prev) {
          emit("update:searchText", state.searchText);
        }
      }
    );

    return {
      allCategories,
      searchRef,
      state,
      focusSearchInput,
    };
  },
});
