

























































































import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  watch,
  PropType,
  computed,
} from "@vue/composition-api";
import { useWindowSize, useDebounce } from "@vueuse/core";
import { debounce } from "lodash-es";
import { nanoid } from "nanoid";
import Muuri, { GridOptions, Item } from "muuri";

// import { HOST } from "@/constants";

import { FundingProject, FundingProjectItem } from "../types";
import { transformProjects } from "../helper";

import FundingProjectDetails from "./FundingProjectDetails.vue";
import Grid from "muuri";
import { defaultAdditionalProjects } from "../constants";

const gridOptions: GridOptions = {
  dragEnabled: false,
  layoutOnResize: true,
  layout: {
    fillGaps: true,
    rounding: true,
  },
};

const FUNDING_PROJECTS_GRID_CONSTANTS = {
  initialLimit: {
    mobile: 3, // + 1 ("no project")
    desktop: 11, // + 1 ("no project")
  },
  showMoreAmount: {
    mobile: 3,
    desktop: 4,
  },
};

export default defineComponent({
  name: "FundingProjectsGrid",
  props: {
    category: {
      type: String,
      default: "",
    },
    searchText: {
      type: String,
      default: "",
    },
    projects: {
      type: Array as PropType<FundingProject[]>,
      default: () => [],
    },
    validationFailed: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root, emit, attrs }) {
    const id = `muuri-grid-${nanoid()}`;

    const { width: windowWidth } = useWindowSize();
    const debouncedWindowWidth = useDebounce(windowWidth, 500);

    const deviceContext = ref(getDeviceContextFromWindowSize());
    const grid = ref<Grid>();
    const state = reactive({
      selected:
        attrs.selected || ({} as Record<string, unknown> | FundingProjectItem),
      loading: true,
      limit: FUNDING_PROJECTS_GRID_CONSTANTS.initialLimit[deviceContext.value],
    });

    const allProjects = computed(() => [
      ...transformProjects(props.projects),
      ...defaultAdditionalProjects,
    ]);

    const allProjectsCount = computed(() => allProjects.value.length);

    const allProjectsVisible = computed(
      () => state.limit >= allProjectsCount.value
    );

    const showLoadMoreButton = computed(
      () =>
        !allProjectsVisible.value && state.limit < allProjectsCount.value - 1 // - 1 ("no project")
    );

    function getDeviceContextFromWindowSize(): "mobile" | "desktop" {
      return windowWidth.value < 1024 ? "mobile" : "desktop";
    }

    function setDeviceContext() {
      deviceContext.value = getDeviceContextFromWindowSize();
    }

    function setLoading(loading: boolean) {
      emit("funding-projects-grid::loading", loading);
      state.loading = loading;
    }

    function filterGrid(options: Record<string, unknown> = {}) {
      let index = 0;
      grid.value?.filter((item: Item) => {
        const element = item.getElement();

        const isShown =
          index < state.limit || element?.hasAttribute("data-show");
        const isActive = element?.hasAttribute("data-active");
        const shouldShowAlways = element?.hasAttribute("data-show-always");

        const title = element?.getAttribute("data-title") || "";
        const categories = element?.getAttribute("data-categories") || "";
        const combinedSearchText = (title + categories)
          .toLowerCase()
          .replace(/\s/g, "");

        const isInCategory = props.category
          ? categories.includes(props.category)
          : true;

        const isInSearchText = combinedSearchText.includes(
          props.searchText.toLowerCase()
        );

        const isInGrid = !!(
          shouldShowAlways ||
          isActive ||
          (isShown && isInCategory && isInSearchText)
        );

        isInGrid && index++;

        return isInGrid;
      }, options);
    }

    const debouncedFilterGrid = debounce(filterGrid, 100);

    function setupGrid() {
      grid.value = new Muuri(`#${id}`, gridOptions);
      filterGrid({ instant: true });
      setLoading(false);
    }

    function selectProject(project: FundingProjectItem) {
      state.selected = project;
    }

    function showProjectDetails(project: FundingProjectItem) {
      root.$modal.show(
        FundingProjectDetails,
        { project, handleSelectProject: selectProject },
        {
          name: project.id,
          height: "auto",
        }
      );
    }

    function showMoreProjects() {
      state.limit =
        state.limit +
        FUNDING_PROJECTS_GRID_CONSTANTS.showMoreAmount[deviceContext.value];

      root.$nextTick(filterGrid);
    }

    watch(debouncedWindowWidth, () => {
      const prevDeviceContext = deviceContext.value;

      setDeviceContext();
      if (
        state.limit ===
        FUNDING_PROJECTS_GRID_CONSTANTS.initialLimit[prevDeviceContext]
      ) {
        state.limit =
          FUNDING_PROJECTS_GRID_CONSTANTS.initialLimit[deviceContext.value];

        root.$nextTick(filterGrid);
      }
    });

    watch(
      () => props.category,
      () => filterGrid()
    );

    watch(
      () => props.searchText,
      () => debouncedFilterGrid()
    );

    watch(
      () => state.selected,
      () => emit("update:selected", state.selected)
    );

    onMounted(() => {
      setLoading(true);
      setupGrid();
    });

    return {
      allProjects,
      allProjectsVisible,
      showLoadMoreButton,
      id,
      grid,
      state,
      selectProject,
      showProjectDetails,
      showMoreProjects,
      host: window.location.origin ?? 'https://www.evo-energie.de',
    };
  },
});
