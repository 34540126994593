




























import { get } from "lodash-es";

import { GetProductType } from "@/features/tariff-calculator/state/getters";
import { TransformedProductOptionValue } from "@/api/sap/@types";

import RadioButtons from "@/features/usage-calculator/components/RadioButtons.vue";
import BaseToggle from "@/shared/components/base/BaseToggle/BaseToggle.vue";

import { defineComponent, PropType, computed } from "@vue/composition-api";

export default defineComponent({
  name: "TariffCalculatorProductOption",
  props: {
    productData: {
      type: Object as PropType<GetProductType>,
    },
    id: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    selected: {
      type: [String, Number],
      default: "",
    },
    options: {
      type: Array as PropType<TransformedProductOptionValue[]>,
      default: () => [],
    },
    optionType: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
  },
  components: {
    RadioButtons,
    BaseToggle,
  },
  setup(props, ctx) {
    function setSelectedValue(e: string | number) {
      ctx.emit("set-product-option", {
        productId: props.productData?.id,
        optionId: props.id,
        selectedValue: e,
        type: props.optionType,
      });
    }

    const radioOptions = computed(() => {
      return props.options?.map((option) => {
        switch (props.optionType) {
          default:
            return {
              id: option.id,
              title: option.text,
              value: option.value,
            };
        }
      });
    });

    return {
      get,
      setSelectedValue,
      radioOptions,
    };
  },
});
