




























import { defineComponent, computed } from "@vue/composition-api";

import {
  RegistrationOverviewTextWithIconIndicator,
  RegistrationOverviewDataTable,
} from "../../../RegistrationOverview";

import store from "@/store";

export default defineComponent({
  name: "RegistrationPaymentStepOverview",
  props: {
    paymentMethod: {
      type: String,
    },
    docText: {
      type: String,
      required: true,
    },
  },
  components: {
    RegistrationOverviewTextWithIconIndicator,
    RegistrationOverviewDataTable,
  },
  setup() {
    const registrationPaymentStepData = computed(
      () => store.state.registrationRoute.formData.registrationPaymentStep
    );

    const docChecked = computed(() => registrationPaymentStepData.value.doc);
    const paymentData = computed(() =>
      [
        {
          title: "Kontoinhaber",
          value: registrationPaymentStepData.value.accountHolder,
        },
        { title: "IBAN", value: registrationPaymentStepData.value.iban },
        { title: "BIC", value: registrationPaymentStepData.value.swift },
        { title: "Bank", value: registrationPaymentStepData.value.bankName },
      ].filter((item) => !!item.value)
    );

    const paymentMethodText = computed(
      () => registrationPaymentStepData.value.paymentMethodData.title
    );

    return {
      docChecked,
      paymentData,
      paymentMethodText,
    };
  },
});
