















import { defineComponent, computed } from "@vue/composition-api";

import store from "@/store";

import { RegistrationStepProps } from "../RegistrationStep/registration-step.types";
import RegistrationStep, { RegistrationStepState } from "../RegistrationStep";
import { registrationSteps } from "./steps";
import { CustomEditCallback } from "./registration-steps.types";
import { registrationTariffStepCustomEditCallback } from "./steps/RegistrationTariffStep";

export default defineComponent({
  name: "RegistrationSteps",
  components: {
    RegistrationStep,
    ...registrationSteps,
  },
  setup() {
    const steps = computed<RegistrationStepProps[]>(
      () => store.state.registrationRoute.steps
    );

    function addCustomEditCallback() {
      const customEditCallbacks: CustomEditCallback[] = [
        registrationTariffStepCustomEditCallback,
      ];

      customEditCallbacks.forEach((item) => {
        const index = steps.value.findIndex((step) => step.id === item.id);
        if (index > -1) {
          steps.value[index].customToggleEdit = item.customToggleEdit;
        }
      });
    }

    addCustomEditCallback();

    return {
      RegistrationStepState,
      steps,
    };
  },
});
