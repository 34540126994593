import store from "@/store";

import { CustomEditCallback } from "../../registration-steps.types";

export function tariffStepEditCallback(): void {
  store.dispatch.registrationRouteResetProduct();
}

export const registrationTariffStepCustomEditCallback: CustomEditCallback = {
  id: "RegistrationTariffStep",
  customToggleEdit: tariffStepEditCallback,
};

export { default } from "./RegistrationTariffStep.vue";
