















import {
  computed,
  defineComponent,
  ref,
  watch,
  watchEffect,
} from "@vue/composition-api";
import IBAN, { printFormat } from "iban";

import AsyncInputField from "@/shared/components/AsyncInputField";
import { useAsyncComponent } from "@/hooks/useAsyncComponent";
import { getBankData, BankData, cancelGetBankName } from "@/api/sap";

export default defineComponent({
  name: "RegistrationIBAN",
  components: {
    AsyncInputField,
  },
  setup(_, ctx) {
    const { isLoading, withLoading } = useAsyncComponent();

    const ibanModel = ref(ctx.attrs.value || "");
    const iban = computed({
      get: () => printFormat(ibanModel.value, " "),
      set: (val) => {
        ibanModel.value = val.replace(/\s/g, "").toUpperCase().slice(0, 22);
      },
    });
    const bankData = ref<BankData | Record<string, unknown>>({});
    const customErrors = ref<string[]>([]);

    watch(iban, async (iban, prevIban) => {
      if (iban === prevIban) {
        return;
      }

      requestBankData();
    });

    watchEffect(() => syncLoading());

    async function requestBankData() {
      cancelBankDataRequest();
      resetBankData();
      resetErrors();

      if (!IBAN.isValid(iban.value)) {
        return;
      }

      try {
        const response = await withLoading(getBankData(iban.value));

        if (response.errorMsg) {
          customErrors.value = [response.errorMsg, ...customErrors.value];
        } else {
          bankData.value = response.bankData;
        }
      } catch (err) {
        if (err.message !== "cancelBankDataRequest") {
          customErrors.value = [err.userMessage, ...customErrors.value];
        }
      } finally {
        syncBankData();
        syncErrors();
      }
    }

    function cancelBankDataRequest() {
      if (isLoading.value) {
        cancelGetBankName("cancelBankDataRequest");
      }
    }

    function emitIBAN() {
      ctx.emit("input", ibanModel.value);
    }

    function resetBankData() {
      bankData.value = {};
      syncBankData();
    }

    function resetErrors() {
      customErrors.value = [];
    }

    function syncBankData() {
      ctx.emit("update:bankName", bankData.value.bankName);
      ctx.emit("update:swift", bankData.value.swift);
      ctx.emit("update:bankCode", bankData.value.bankCode);
    }

    function syncErrors() {
      ctx.emit("update:errors", customErrors.value);
    }

    function syncLoading() {
      ctx.emit("update:loading", isLoading.value);
    }

    return {
      customErrors,
      requestBankData,
      iban,
      emitIBAN,
      isLoading,
    };
  },
});
