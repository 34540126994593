















































import {
  defineComponent,
  ref,
  PropType,
  Ref,
  computed,
  watch,
} from "@vue/composition-api";
import { useThrottle } from "@vueuse/core";
import { mask } from "vue-the-mask";

import InfoPopup from "@/shared/components/InfoPopup";
import InputSlider, { SliderOptions } from "@/shared/components/InputSlider";
import {
  FillIconByValueItemType,
  useFillIconByValue,
} from "@/hooks/useFillIconByValue.hook";
import { useRandomPlaceholder } from "@/hooks/useRandomPlaceholder.hook";

import featureConfig from "@/features/tariff-calculator/config";

export default defineComponent({
  name: "TariffCalculatorConsumptionSlider",
  directives: {
    mask,
  },
  props: {
    title: {
      type: String,
    },
    name: {
      type: String,
    },
    consumptionType: {
      type: String,
    },
    fillIconItems: {
      type: Array as PropType<FillIconByValueItemType[]>,
      default: () => [],
    },
    iconName: {
      type: String,
    },
    sliderOptions: {
      type: Object as PropType<SliderOptions>,
      default: () => featureConfig.defaults.sliderOptions,
    },
  },
  components: {
    InfoPopup,
    InputSlider,
  },
  setup(props, context) {
    function onChange(val: number | string) {
      context.emit("input", val);
    }

    const iconWrapperRef = ref(null);
    const powerConsumption: Ref<string | number | null> = ref(null);
    const { placeholder } = useRandomPlaceholder(props.fillIconItems as never);

    useThrottle(powerConsumption, 50);

    const sliderInput = computed({
      get: () =>
        Math.min(
          powerConsumption.value as number,
          props.sliderOptions.max || 30000
        ),
      set: (val) => {
        powerConsumption.value = val;
      },
    });

    watch(
      () => context.attrs.value,
      (val, prevVal) => {
        if (powerConsumption.value || val === prevVal) {
          return;
        }

        powerConsumption.value = val;
      },
      { immediate: true }
    );

    useFillIconByValue(
      iconWrapperRef,
      props.fillIconItems,
      sliderInput,
      onChange
    );

    return {
      placeholder,
      powerConsumption,
      sliderInput,
      iconWrapperRef,
      onChange,
    };
  },
});
