













import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
  name: "TariffCalculatorSwitchTab",
  props: {
    iconName: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const activeClasses = computed(() =>
      props.active ? "scale-117 bg-orange-500 text-white" : "text-orange-500"
    );

    return {
      activeClasses,
    };
  },
});
