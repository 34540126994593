/* eslint-disable no-useless-escape */
import Vue from "@/helper/initVue";
import store from "@/store";

import RegistrationRoute from "@/features/registration-route";
import { RegistrationRouteConfig } from "@/features/registration-route/components/RegistrationRoute";

const useDummyConfig = process.env.NODE_ENV === "development";
const dummyConfig = `
  {
    "showFundingProjects": false,
    "preventRedirect": false,
    "links": {
      "cancellationPolicy": "http:\/\/www.evo.test\/fileadmin\/user_upload\/Test-Dateien\/dummy.pdf",
      "privacyPolicy": "http:\/\/www.evo.test\/fileadmin\/user_upload\/Test-Dateien\/dummy.pdf",
      "creditAssessment": "http:\/\/www.evo.test\/fileadmin\/user_upload\/Test-Dateien\/dummy.pdf",
      "egbgbInformation": "http:\/\/www.evo.test\/fileadmin\/user_upload\/Test-Dateien\/dummy.pdf",
      "generalSupplyConditions": {
        "electricity": "http:\/\/www.evo.test\/fileadmin\/user_upload\/EEEEEEEEEEEETest-Dateien\/dummy.pdf",
        "gas": "http:\/\/www.evo.test\/fileadmin\/user_upload\/GGGGGGGGGGGGGGTest-Dateien\/dummy.pdf"
      },
      "supplementaryTerms": {
        "electricity": "http:\/\/www.evo.test\/fileadmin\/user_upload\/EEEEEEEEEEEEEEETest-Dateien\/dummy.pdf",
        "gas": "http:\/\/www.evo.test\/fileadmin\/user_upload\/GGGGGGGGGGGGGTest-Dateien\/dummy.pdf"
      },
      "successPages": {
        "privateCustomer": {
          "alreadyCustomer": "http:\/\/www.evo.test\/marktkommunikation",
          "electricity": "http:\/\/www.evo.test\/danke\/upselling\/strom",
          "gas": "http:\/\/www.evo.test\/danke\/upselling\/gas",
          "heating": "http:\/\/www.evo.test\/danke\/upselling\/heizstrom"
        },
        "businessCustomer": {
          "alreadyCustomer": "http:\/\/www.evo.test\/marktkommunikation",
          "electricity": "http:\/\/www.evo.test\/danke\/upselling\/strom",
          "gas": "http:\/\/www.evo.test\/danke\/upselling\/gas",
          "heating": "http:\/\/www.evo.test\/danke\/upselling\/heizstrom"
        }
      },
      "errorPage": "http:\/\/www.evo.test\/es-tut-uns-leid\/"
    }
  }
` as const;
export const parsedDummyConfig = JSON.parse(dummyConfig);

export const initRegistrationRoute = () => {
  const el = document.getElementById("registration-route");

  if (!el) {
    return;
  }

  try {
    const dataConfig = useDummyConfig
      ? dummyConfig
      : el.getAttribute("data-config");

    if (!dataConfig) {
      return;
    }

    const parsedConfig: RegistrationRouteConfig = JSON.parse(dataConfig);

    new Vue({
      el,
      store: store.original, // Inject the classic Vuex store
      render: (h) =>
        h(RegistrationRoute, {
          props: {
            config: parsedConfig,
          },
        }),
    });
  } catch (err) {
    console.error('Failed to load module "RegistrationRoute"');
  }
};

initRegistrationRoute();
