



























import {
  defineComponent,
  reactive,
  ref,
  watch,
  computed,
  onBeforeMount,
} from "@vue/composition-api";

import { findIndex, get, isEmpty } from "lodash-es";

import LoadingOverlay from "@/shared/components/LoadingOverlay";
import store from "@/store";
import { FundingProject } from "../types";

import FundingProjectsFilter from "./FundingProjectsFilter.vue";
import FundingProjectsGrid from "./FundingProjectsGrid.vue";
import { transformProject } from "../helper";
import { RegistrationStepState } from "@/features/registration-route/components/RegistrationStep";
import { RegistrationStepId } from "@/features/registration-route/state";

export default defineComponent({
  name: "FundingProjects",
  components: {
    LoadingOverlay,
    FundingProjectsFilter,
    FundingProjectsGrid,
  },
  props: {
    validationFailed: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const categories = ref<string[]>(
      store.state.registrationRoute.fundingProjects.categories
    );
    const projects = ref<FundingProject[]>(
      store.state.registrationRoute.fundingProjects.projects
    );
    const affiliateId = computed(
      () =>
        store.state.registrationRoute.formData.registrationFundingProjectsStep
          .affiliateId
    );
    const fundingProject = computed(
      () =>
        store.state.registrationRoute.formData.registrationFundingProjectsStep
          .fundingProject
    );

    const state = reactive({
      category: "",
      searchText: "",
      selected: fundingProject.value,
      loading: true,
    });

    function setLoading(loading: boolean) {
      state.loading = loading;
      emit("update:loading", loading);
    }

    async function fetchFundingProjects() {
      const data = await store.dispatch.fetchFundingProjects();

      const result = {
        categories: get(data, "categories", []),
        projects: get(data, "projects", []),
      };

      categories.value = result.categories;
      projects.value = result.projects;

      store.commit.setFundingCategories(result.categories);
      store.commit.setFundingProjects(result.projects);

      return !!data;
    }

    async function fetchFundingProject() {
      const data = await store.dispatch.fetchFundingProject(affiliateId.value);

      if (!isEmpty(data)) {
        store.commit.setFundingProjects([data]);

        const fundingProjectStepConfigIndex = findIndex(
          store.state.registrationRoute.steps,
          {
            id: RegistrationStepId.REGISTRATION_FUNDING_PROJECTS_STEP,
          }
        );

        const fundingProject = transformProject(data);

        store.commit.setFundingProjectsStep({
          affiliateId: affiliateId.value,
          fundingProject,
        });

        store.commit.setStepData({
          index: fundingProjectStepConfigIndex,
          stepData: {
            state: RegistrationStepState.visited,
            locked: true,
          },
        });

        store.commit.setActiveStep(2);
      }

      return !!data;
    }

    async function setupFundingProjects() {
      setLoading(true);

      let fetchSuccessful = false;
      if (affiliateId.value && !fundingProject.value?.affiliateId) {
        fetchSuccessful = await fetchFundingProject();
      }

      if (!fetchSuccessful) {
        await fetchFundingProjects();
      }
    }

    onBeforeMount(() => {
      if (projects.value.length === 0) {
        setupFundingProjects();
      }
    });

    watch(
      () => state.selected,
      () => {
        emit("input", state.selected);
      }
    );

    return {
      affiliateId,
      fundingProject,
      state,
      categories,
      projects,
      setLoading,
    };
  },
});
