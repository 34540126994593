



















import { DivisionType } from "@/api/sap";

import {
  defineComponent,
  PropType,
  ref,
  onMounted,
} from "@vue/composition-api";

export default defineComponent({
  name: "TariffCalculatorJumpMarkButton",
  props: {
    availableTabs: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
  },
  setup(props) {
    const show = ref(true);

    let tabs: {
      id: number;
      tabIcon: string;
      slotName: string;
      mode: keyof typeof DivisionType;
    }[] = [
      {
        id: 0,
        tabIcon: "PowerPlug",
        slotName: "electricity",
        mode: "Electricity",
      },
      {
        id: 1,
        tabIcon: "GasFlame",
        slotName: "gas",
        mode: "Gas",
      },
      {
        id: 2,
        tabIcon: "HeatingCurrent",
        slotName: "heating",
        mode: "Heating",
      },
    ];

    onMounted(() => {
      showIfCalculatorNotVisible();
    });

    if (props.availableTabs.length > 0) {
      tabs = tabs.filter((tab) => props.availableTabs.indexOf(tab.id) > -1);
    }
    function backToCalculator() {
      const header = document.querySelector<HTMLElement>("header");
      const element = document.querySelector<HTMLElement>(
        ".tariff-calculator-switch"
      );

      if (!element || !header) {
        return;
      }

      const offset = header.getBoundingClientRect().height;
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      let elementPosition = elementRect - bodyRect;
      let offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }

    function showIfCalculatorNotVisible() {
      const element = document.querySelector<HTMLElement>(
        ".tariff-calculator-switch"
      );

      if (!element) {
        return;
      }

      let io = new IntersectionObserver((entries) => {
        if (entries.some((entry) => entry.intersectionRatio > 0)) {
          show.value = false;
        } else {
          show.value = true;
        }
      });

      io.observe(element);
    }

    return {
      show,
      tabs,
      backToCalculator,
    };
  },
});
