






































import {
  defineComponent,
  ref,
  PropType,
  watch,
  computed,
} from "@vue/composition-api";

import { DivisionType } from "@/api/sap";
import store from "@/store";

import LoadingOverlay from "@/shared/components/LoadingOverlay";

import TariffCalculatorSwitchTab from "../TariffCalculatorSwitchTab";

export default defineComponent({
  name: "TariffCalculatorSwitch",
  props: {
    availableTabs: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    imageUrl: {
      type: String,
      default: "",
    },
  },
  components: {
    LoadingOverlay,
    TariffCalculatorSwitchTab,
  },
  setup(props) {
    const isLoading = computed(() => store.state.tariffCalculator.loading);
    const calculatorMode = computed(
      () => store.state.tariffCalculator.currentMode
    );

    const tabIndex = ref(0);

    let tabs: {
      id: number;
      tabIcon: string;
      slotName: string;
      mode: keyof typeof DivisionType;
    }[] = [
      {
        id: 0,
        tabIcon: "PowerPlug",
        slotName: "electricity",
        mode: "Electricity",
      },
      {
        id: 1,
        tabIcon: "GasFlame",
        slotName: "gas",
        mode: "Gas",
      },
      {
        id: 2,
        tabIcon: "HeatingCurrent",
        slotName: "heating",
        mode: "Heating",
      },
    ];

    if (props.availableTabs.length > 0) {
      tabs = tabs.filter((tab) => props.availableTabs.indexOf(tab.id) > -1);
    }

    store.commit.setAvailableCalculators(tabs);

    watch(
      calculatorMode,
      (calculatorMode, prevCalculatorMode) => {
        if (calculatorMode === prevCalculatorMode) {
          return;
        }

        if (calculatorMode) {
          const index = tabs.findIndex((item) => item.mode === calculatorMode);

          if (index >= 0) {
            tabIndex.value = index;
          }
        }
      },
      { immediate: true }
    );

    return {
      tabs,
      tabIndex,
      isLoading,
    };
  },
});
