













import { defineComponent, computed, PropType } from "@vue/composition-api";

import { IS_PROTOTYPE } from "@/constants";
import { parsedDummyConfig } from "@/modules/registration-route.module";

import store from "@/store";
import { useLoadingEvent } from "@/hooks/useLoadingEvent.hook";
import LoadingOverlay from "@/shared/components/LoadingOverlay";

import RegistrationSteps from "../RegistrationSteps";

import { RegistrationRouteConfig } from ".";

const defaultConfigProps = IS_PROTOTYPE ? parsedDummyConfig : {};

export default defineComponent({
  name: "RegistrationRoute",
  components: {
    LoadingOverlay,
    RegistrationSteps,
  },
  props: {
    config: {
      type: Object as PropType<RegistrationRouteConfig>,
      default: () => defaultConfigProps as RegistrationRouteConfig,
    },
  },
  setup(props, ctx) {
    store.commit.setRegistrationRouteTimestamp();
    store.dispatch.setRegistrationRouteConfig(props.config);

    const { componentIsLoading } = useLoadingEvent({
      root: ctx.root,
      componentID: "RegistrationRoute",
    });

    const isLoadingGlobally = computed(
      () => store.state.registrationRoute.isLoadingGlobally
    );

    const showLoadingOverlay = computed(
      () => isLoadingGlobally.value || componentIsLoading.value
    );

    const showRegistrationRoute = computed(
      () => store.getters.shouldShowRegistrationRoute
    );

    return {
      showRegistrationRoute,
      isLoadingGlobally,
      showLoadingOverlay,
    };
  },
});
