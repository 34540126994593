








































































































































































































































































import { get } from "lodash-es";

import { PriceUnit, Cs67IssPriceComp } from "@/api/sap";
import { formatNumber } from "@/helper";
import { pushEvent } from "@/helper/pushEvent.helper";
import store from "@/store";

import { GetProductType } from "@/features/tariff-calculator/state/getters";
import Loading from "vue-loading-overlay";

import InfoPopup from "@/shared/components/InfoPopup";
import TariffCalculatorProductDetails from "@/features/tariff-calculator/components/TariffCalculatorProductDetails";
import TariffCalculatorProductOptions from "@/features/tariff-calculator/components/TariffCalculatorProductOptions";
import RadioButtons from "@/features/usage-calculator/components/RadioButtons.vue";

import { defineComponent, PropType, computed, ref } from "@vue/composition-api";

export default defineComponent({
  name: "TariffCalculatorProduct",
  props: {
    productData: {
      type: Object as PropType<GetProductType>,
      required: true,
    },
    featured: {
      type: Boolean,
      default: true,
    },
    hasBadge: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
    },
  },
  components: {
    InfoPopup,
    Loading,
    TariffCalculatorProductOptions,
    RadioButtons,
  },
  setup(props, { root }) {
    const isNetto = computed(
      () => props.productData.priceUnit === PriceUnit.Netto
    );

    const workingPrice = computed(() =>
      get(props.productData.prices.workingPrice, "0", {})
    );

    const workingPriceComps = computed(
      () =>
        get(
          workingPrice.value,
          "PREIS_COMP._-CS67_-ISS_PRICE_COMP",
          []
        ) as Cs67IssPriceComp[]
    );

    const hasProviderDifference = computed(
      () => props.productData.defaultProviderDifference != undefined
    );

    const isSpecialProduct = computed(
      () =>
        !!props.productData?.special && props.productData?.special.includes("%")
    );

    function showProductDetails() {
      root.$modal.show(
        TariffCalculatorProductDetails,
        {
          productData: props.productData,
          hasBadge: props.hasBadge,
          config: props.config,
        },
        { name: props.productData.id, height: "auto" }
      );
    }

    const hasOptions = (productData: GetProductType) =>
      !!productData?.productOptions?.length;

    const productOptionFetching = ref(false);

    async function setProductOption(data: {
      productId: string | number;
      optionId: string | number;
      selectedValue: string | boolean | number | undefined;
      type: string;
    }) {
      productOptionFetching.value = true;
      const { productId, optionId, selectedValue, type } = data;
      switch (type) {
        case "productCombiOption":
          await store.dispatch.updateProductSelectedCombiOption({
            productId,
            optionId,
            selectedValue,
          });
          break;
        default:
          await store.dispatch.updateProductSelectedOption({
            productId,
            optionId,
            selectedValue,
          });
          break;
      }
      productOptionFetching.value = false;
    }

    function concludeTariff() {
      store.dispatch.startRegistrationRoute({
        product: props.productData,
      });

      pushEvent({
        event: "addToCart",
        ecommerce: {
          currencyCode: "EUR",
          add: {
            products: [
              {
                name: props.productData.name,
                id: props.productData.id,
                price: formatNumber(
                  get(props.productData.prices, [
                    "annualCosts",
                    "0",
                    props.productData.priceUnit,
                  ])
                ),
                brand: "EVO",
                category: props.productData.calculatorMode,
                quantity: 1,
              },
            ],
          },
        },
      });
    }

    return {
      get,
      isNetto,
      hasProviderDifference,
      hasOptions,
      showProductDetails,
      formatNumber,
      workingPriceComps,
      concludeTariff,
      setProductOption,
      isSpecialProduct,
      productOptionFetching,
    };
  },
});
