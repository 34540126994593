

































import { defineComponent } from "@vue/composition-api";

import { BaseRadioButtonProps } from "@/shared/components/base/BaseRadioButton";
import InfoPopup from "@/shared/components/InfoPopup";

import TariffCalculatorHCMInfoModal from "./TariffCalculatorHCMInfoModal.vue";

export default defineComponent({
  name: "TariffCalculatorHCM",
  components: {
    InfoPopup,
    TariffCalculatorHCMInfoModal,
  },
  setup() {
    const options: BaseRadioButtonProps[] = [
      {
        name: "hcmAvailable",
        value: "1",
        title: "Ja",
        checked: true,
      },
      {
        name: "hcmAvailable",
        value: "0",
        title: "Nein",
        checked: false,
      },
    ];

    return {
      options,
    };
  },
});
