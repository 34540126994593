































import { defineComponent, PropType, computed } from "@vue/composition-api";

import { DivisionType } from "@/api/sap";

import store from "@/store";

import { useWindowUtils } from "@/hooks/useWindowUtils.hook";

import TariffCalculatorElectricityView from "@/features/tariff-calculator/views/TariffCalculatorElectricityView";
import TariffCalculatorGasView from "@/features/tariff-calculator/views/TariffCalculatorGasView";
import TariffCalculatorHeatingView from "@/features/tariff-calculator/views/TariffCalculatorHeatingView";
import TariffCalculatorSwitch from "@/features/tariff-calculator/components/TariffCalculatorSwitch";
import TariffCalculatorProducts from "@/features/tariff-calculator/components/TariffCalculatorProducts";
import TariffCalculatorJumpMarkButton from "@/features/tariff-calculator/components/TariffCalculatorJumpMarkButton";

import { TariffCalculatorConfig } from ".";

export default defineComponent({
  name: "TariffCalculator",
  components: {
    TariffCalculatorSwitch,
    TariffCalculatorElectricityView,
    TariffCalculatorGasView,
    TariffCalculatorHeatingView,
    TariffCalculatorProducts,
    TariffCalculatorJumpMarkButton,
  },
  props: {
    config: {
      type: Object as PropType<TariffCalculatorConfig>,
      default: () => ({} as TariffCalculatorConfig),
    },
  },
  setup(props) {
    store.commit.setTariffCalculatorConfig(props.config);

    const { getParams } = useWindowUtils();
    const params = getParams();

    const showProducts = computed(() => {
      const showProductsProp = props.config.showProducts;
      const hasLastRequest =
        Object.keys(store.state.tariffCalculator.lastRequest).length > 0;

      return (
        (showProductsProp && params) ||
        (showProductsProp && hasLastRequest) ||
        !!params.calculatorMode
      );
    });

    const showTariffCalculator = computed(
      () => !store.getters.shouldShowRegistrationRoute
    );

    if (params.calculatorMode) {
      store.commit.setCurrentMode(
        params.calculatorMode as keyof typeof DivisionType
      );
    }

    return {
      params,
      showTariffCalculator,
      showProducts,
    };
  },
});
