import RegistrationTariffStep from "@/features/registration-route/components/RegistrationSteps/steps/RegistrationTariffStep";
import RegistrationFundingProjectsStep from "@/features/registration-route/components/RegistrationSteps/steps/RegistrationFundingProjectsStep";
import RegistrationPersonalDetailsStep from "@/features/registration-route/components/RegistrationSteps/steps/RegistrationPersonalDetailsStep";
import RegistrationPaymentStep from "@/features/registration-route/components/RegistrationSteps/steps/RegistrationPaymentStep";
import RegistrationDeliveryStep from "@/features/registration-route/components/RegistrationSteps/steps/RegistrationDeliveryStep";
import RegistrationCompleteStep from "@/features/registration-route/components/RegistrationSteps/steps/RegistrationCompleteStep";

export const registrationSteps = {
  RegistrationTariffStep,
  RegistrationFundingProjectsStep,
  RegistrationPersonalDetailsStep,
  RegistrationPaymentStep,
  RegistrationDeliveryStep,
  RegistrationCompleteStep,
};

// export default {
//   RegistrationTariffStep: () =>
//     import(
//       "@/features/registration-route/components/RegistrationSteps/steps/RegistrationTariffStep"
//     ),
//   RegistrationFundingProjectsStep: () =>
//     import(
//       "@/features/registration-route/components/RegistrationSteps/steps/RegistrationFundingProjectsStep"
//     ),
//   RegistrationPersonalDetailsStep: () =>
//     import(
//       "@/features/registration-route/components/RegistrationSteps/steps/RegistrationPersonalDetailsStep"
//     ),
//   RegistrationPaymentStep: () =>
//     import(
//       "@/features/registration-route/components/RegistrationSteps/steps/RegistrationPaymentStep"
//     ),
//   RegistrationDeliveryStep: () =>
//     import(
//       "@/features/registration-route/components/RegistrationSteps/steps/RegistrationDeliveryStep"
//     ),
//   RegistrationCompleteStep: () =>
//     import(
//       "@/features/registration-route/components/RegistrationSteps/steps/RegistrationCompleteStep"
//     ),
// };
