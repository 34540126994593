





































import { defineComponent, PropType, computed } from "@vue/composition-api";

import { HeatingType, CustomerType } from "@/api/sap";
import { useRandomPlaceholder } from "@/hooks/useRandomPlaceholder.hook";

import InfoPopup from "@/shared/components/InfoPopup";

export default defineComponent({
  name: "TariffCalculatorHeatingTypeSelect",
  props: {
    customerType: {
      type: String as PropType<CustomerType>,
    },
  },
  components: {
    InfoPopup,
  },
  setup(props) {
    const options = computed(() => {
      const items = [
        {
          label: "Wärmepumpe",
          value: HeatingType.HeatingPump,
        },
      ];

      if (props.customerType === CustomerType.PrivateCustomer) {
        items.unshift({
          label: "Nachtspeicher",
          value: HeatingType.NightStorage,
        });
      }

      return items;
    });

    const computedPlaceholder = computed(() => {
      const { placeholder } = useRandomPlaceholder(options.value);
      return placeholder;
    });

    return { options, placeholder: computedPlaceholder };
  },
});
