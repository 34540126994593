import store from "@/store";
import { onActivated } from "@vue/composition-api";
import { set } from "lodash-es";

export function useInitTariffCalc(
  /* eslint-disable @typescript-eslint/no-explicit-any */
  state: Record<string, any>,
  initialValues: Record<string, any>
  /* eslint-enable @typescript-eslint/no-explicit-any */
) {
  function getInitialStateValues() {
    const stateKeys = Object.keys(state);
    const filteredInitialValues = {};

    if (initialValues.calculatorMode === state.calculatorMode) {
      stateKeys.forEach((key) => {
        set(filteredInitialValues, key, initialValues[key]);
      });
    }

    if (initialValues.affiliateId) {
      set(filteredInitialValues, "affiliateId", initialValues.affiliateId);
    }

    if (initialValues.serviceNumber) {
      set(filteredInitialValues, "serviceNumber", initialValues.serviceNumber);
    }

    if (initialValues.allowedProductIds) {
      set(
        filteredInitialValues,
        "allowedProductIds",
        initialValues.allowedProductIds
      );
    }

    return filteredInitialValues;
  }

  onActivated(() => {
    store.commit.setCurrentMode(state.calculatorMode);
  });

  return {
    getInitialStateValues,
  };
}
