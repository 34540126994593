import store from "@/store";
import { computed } from "@vue/composition-api";

export function useTariffCalcValues() {
  const errors = computed(() => store.state.tariffCalculator.errors);

  return {
    errors,
  };
}
