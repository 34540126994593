import { isArray, kebabCase, lowerCase } from "lodash-es";
import { nanoid } from "nanoid";

import { FundingProject } from "./types";

export const transformCategory = (category: string) => {
  const value = kebabCase(lowerCase(category));

  return {
    title: category,
    value,
    name: category,
  };
};

export const transformCategories = (categories: string[]) =>
  categories.map((category) => transformCategory(category));

export const transformProject = (project: FundingProject) => {
  const uuid = "" + project.uid ?? nanoid();

  const gridTitle = project.gridTitle || project.description;
  const gridImage = isArray(project.gridImage)
    ? {}
    : {
        ...project.gridImage,
        alternative:
          project.gridImage.alternative ||
          project.gridImage.title ||
          project.gridImage.description,
      };

  const detailTitle = project.detailTitle || project.description;
  const detailImage = isArray(project.detailImage)
    ? {}
    : {
        ...project.detailImage,
        alternative:
          project.detailImage.alternative ||
          project.detailImage.title ||
          project.detailImage.description,
      };

  const hasDetailView =
    (!!detailImage || !!detailTitle || !!project.detailSubtitle) &&
    !!project.detailText;

  const transformedProject = {
    id: uuid,
    affiliateId: project.affiliateId,
    serviceNo: project.serviceNo || {},
    categories: project.gridCategory.toLowerCase(),
    hasDetailView,
    grid: {
      title: gridTitle,
      image: gridImage,
    },
    detail: {
      title: detailTitle,
      subTitle: project.detailSubtitle,
      text: project.detailText,
      image: detailImage,
      additionalImages: project.detailAdditionalImages,
    },
  };

  return transformedProject;
};

export const transformProjects = (projects: FundingProject[]) =>
  projects.map((project) => transformProject(project));
