




import { defineComponent } from "@vue/composition-api";
import { useTariffCalcValues } from "../../hooks";

export default defineComponent({
  name: "TariffCalculatorErrors",
  setup() {
    const { errors } = useTariffCalcValues();

    return {
      errors,
    };
  },
});
