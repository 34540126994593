













import { defineComponent, ref } from "@vue/composition-api";
import { useAsyncComponent } from "@/hooks/useAsyncComponent";
import { getAllBranchtypes } from "@/api/sap";

export default defineComponent({
  name: "RegistrationBranchTypes",
  setup(props, ctx) {
    const { withLoading } = useAsyncComponent({
      root: ctx.root,
      ids: ["RegistrationPersonalDetailsStep"],
    });

    const options = ref([
      {
        SPRAS: "D",
        ISTYPE: "ZEVO",
        IND_SECTOR: "Z0000",
        TEXT: "Sonstige",
      },
    ]);

    withLoading(getAllBranchtypes()).then((branchTypes) => {
      if (branchTypes) {
        options.value = branchTypes ?? [];
      }
    });

    return {
      options,
    };
  },
});
