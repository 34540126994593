













import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "TariffCalculatorFormSubmit",
  props: {
    invalid: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Tarif berechnen",
    },
  },
});
