




































































import {
  defineComponent,
  PropType,
  reactive,
  toRefs,
  ref,
  computed,
  watch,
} from "@vue/composition-api";
import { eq, find, lowerCase } from "lodash-es";

import store from "@/store";
import { BaseRadioButtonProps } from "@/shared/components/base/BaseRadioButton";
import LoadingOverlay from "@/shared/components/LoadingOverlay";
import AsyncInputField from "@/shared/components/AsyncInputField";

import { RegistrationStepState } from "../../../RegistrationStep";
import RegistrationStepContent from "../../../RegistrationStepContent";
import RegistrationStepForm from "../../../RegistrationStepForm";
import RegistrationIBAN from "../../../RegistrationIBAN";

import RegistrationPaymentStepOverview from "./RegistrationPaymentStepOverview.vue";

export default defineComponent({
  name: "RegistrationPaymentStep",
  components: {
    RegistrationStepContent,
    RegistrationStepForm,
    RegistrationIBAN,
    LoadingOverlay,
    AsyncInputField,
    RegistrationPaymentStepOverview,
  },
  props: {
    state: {
      type: String as PropType<RegistrationStepState>,
      default: RegistrationStepState.unvisited,
    },
  },
  setup(props) {
    const personalDetails = computed(
      () => store.getters.registrationPersonalDetailsFormData
    );

    const formData = ref({
      ...store.getters.registrationPaymentFormData,
    });

    const contractorIsAccountHolder = computed(() => {
      const { firstName, lastName, companyName } = personalDetails.value;

      const transformedCompanyName = lowerCase(companyName).replace(/\s/g, "");

      const transformedFullName = lowerCase(firstName + lastName).replace(
        /\s/g,
        ""
      );

      const transformedAccountHolder = lowerCase(
        formData.value.accountHolder
      ).replace(/\s/g, "");

      return (
        eq(transformedCompanyName, transformedAccountHolder) ||
        eq(transformedFullName, transformedAccountHolder)
      );
    });

    const contractorCustomErrors = computed(() =>
      !formData.value.accountHolder || contractorIsAccountHolder.value
        ? []
        : ["Der Vertragsnehmer und Kontoinhaber müssen identisch sein"]
    );

    watch(
      () => props.state,
      (currentState, prevState) => {
        if (
          currentState !== prevState &&
          prevState === RegistrationStepState.visited
        ) {
          formData.value = {
            ...store.getters.registrationPaymentFormData,
          };
        }
      }
    );

    watch(
      () => formData.value.paymentMethod,
      (currentState, prevState) => {
        if (currentState !== prevState && prevState === "sepa") {
          formData.value = {
            ...formData.value,
            accountHolder: "",
            bankName: "",
            iban: "",
            swift: "",
            doc: false,
          };
        }
      }
    );

    const state = reactive({
      loading: false,
      ibanCustomErrors: [],
    });

    const paymentMethods = ref<BaseRadioButtonProps[]>([
      {
        name: "paymentMethod",
        value: "sepa",
        title: "SEPA-Lastschrift",
      },
      {
        name: "paymentMethod",
        value: "transfer",
        title: "Überweisung",
      },
    ]);

    const customErrors = computed(() => [
      ...state.ibanCustomErrors,
      ...contractorCustomErrors.value,
    ]);

    const sepaSelected = computed(
      () => formData.value.paymentMethod === "sepa"
    );

    const hintText =
      "<b>Hinweis:</b> Ich kann innerhalb von 8 Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen.";

    const docText = `Ich ermächtige die Energieversorgung Oberhausen AG, Zahlungen von meinem Konto mittels SEPA-Lastschriftverfahren einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der Energieversorgung Oberhausen AG auf mein Konto gezogenen Lastschriften einzulösen.<br/><br/>${hintText}`;

    function onSubmit() {
      store.commit.setPaymentData({
        ...formData.value,
        paymentMethodData: find(paymentMethods.value, [
          "value",
          formData.value.paymentMethod,
        ]),
      });
    }

    return {
      formData,
      ...toRefs(state),
      contractorCustomErrors,
      customErrors,
      paymentMethods,
      sepaSelected,
      contractorIsAccountHolder,
      docText,
      onSubmit,
    };
  },
});
