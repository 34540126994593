










import { defineComponent } from "@vue/composition-api";

import { CustomerType } from "@/api/sap";

import { BaseRadioButtonProps } from "@/shared/components/base/BaseRadioButton";

export default defineComponent({
  name: "TariffCalculatorCustomerType",
  setup() {
    const customerTypes: BaseRadioButtonProps[] = [
      {
        name: "customerType",
        value: CustomerType.PrivateCustomer,
        title: "Privatkunde",
      },
      {
        name: "customerType",
        value: CustomerType.BusinessCustomer,
        title: "Geschäftskunde",
      },
    ];

    return {
      customerTypes,
    };
  },
});
