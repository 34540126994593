




































































import {
  defineComponent,
  PropType,
  ref,
  watch,
  computed,
} from "@vue/composition-api";

import store from "@/store";
// import { HOST } from "@/constants";

import FundingProjects from "@/features/funding-projects";
import { FundingProjectItem } from "@/features/funding-projects/types";

import { RegistrationStepState } from "../../../RegistrationStep";
import RegistrationStepContent from "../../../RegistrationStepContent";
import RegistrationStepForm from "../../../RegistrationStepForm";
import { RegistrationOverviewTextWithIconIndicator } from "../../../RegistrationOverview";

export default defineComponent({
  name: "RegistrationFundingProjectsStep",
  components: {
    FundingProjects,
    RegistrationStepContent,
    RegistrationStepForm,
    RegistrationOverviewTextWithIconIndicator,
  },
  props: {
    state: {
      type: String as PropType<RegistrationStepState>,
      default: RegistrationStepState.unvisited,
    },
  },
  setup() {
    const fundingProject = computed<
      FundingProjectItem | Record<string, unknown>
    >({
      get: () =>
        store.state.registrationRoute.formData.registrationFundingProjectsStep
          .fundingProject,
      set: (val) => {
        store.commit.setFundingProjectsStep({
          fundingProject: fundingProject.value,
        });

        store.state.registrationRoute.formData.registrationFundingProjectsStep.fundingProject = val;
      },
    });

    const loading = ref(true);
    const errors = ref<string[]>([]);

    watch(fundingProject, (prev, current) => {
      if (prev.id === current.id) {
        return;
      }

      errors.value = [];
    });

    function validate() {
      errors.value = [];

      if (!fundingProject.value.id) {
        errors.value.unshift(
          "Bitte wählen Sie ein Förderprojekt aus, oder geben Sie an, dass Sie kein Projekt fördern wollen"
        );

        return false;
      }

      return true;
    }

    function onSubmit() {
      const valid = validate();

      if (valid) {
        store.commit.setFundingProjectsStep({
          fundingProject: fundingProject.value,
        });
      }
    }

    return {
      fundingProject,
      onSubmit,
      host: window.location.origin ?? 'https://www.evo-energie.de',
      loading,
      errors,
    };
  },
});
