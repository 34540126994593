










































































import {
  defineComponent,
  computed,
  ref,
  Ref,
  onMounted,
} from "@vue/composition-api";

import store from "@/store";

import { useWindowUtils } from "@/hooks/useWindowUtils.hook";

import ExtendButton from "@/shared/components/ExtendButton";
import LoadingOverlay from "@/shared/components/LoadingOverlay";

import TariffCalculatorProduct from "@/features/tariff-calculator/components/TariffCalculatorProduct";
import TariffCalculatorInfoBox from "@/features/tariff-calculator/components/TariffCalculatorInfoBox";
import TariffCalculatorErrors from "@/features/tariff-calculator/components/TariffCalculatorErrors";

import {
  useTariffCalcSubmit,
  SubmitValues,
  useTariffCalcValues,
} from "../../hooks";

export default defineComponent({
  name: "TariffCalculatorProducts",
  components: {
    LoadingOverlay,
    TariffCalculatorProduct,
    TariffCalculatorInfoBox,
    TariffCalculatorErrors,
    ExtendButton,
  },
  props: {
    config: {
      type: Object,
    },
  },
  setup() {
    const { getParams } = useWindowUtils();
    const { submitCalculator } = useTariffCalcSubmit();
    const { errors } = useTariffCalcValues();

    const productsRef: Ref<HTMLElement | null> = ref(null);
    const isLoading = computed(() => store.state.tariffCalculator.loading);
    const showAdditionalProducts = ref(false);

    const featuredProducts = computed(() => store.getters.getFeaturedProducts);

    const additionalProducts = computed(
      () => store.getters.getAdditionalProducts
    );

    const isElectricityMode = computed(() => {
      const firstProduct = featuredProducts?.value?.length
        ? featuredProducts.value[0]
        : undefined;
      const mode = firstProduct ? firstProduct.calculatorMode : undefined;

      return mode === "Electricity";
    });

    function toggleAdditionalProducts() {
      showAdditionalProducts.value = !showAdditionalProducts.value;
    }

    onMounted(async () => {
      const params = getParams<SubmitValues>();

      if (params.calculatorMode) {
        await submitCalculator({ values: getParams<SubmitValues>() });
      }
    });

    return {
      isLoading,
      productsRef,
      featuredProducts,
      additionalProducts,
      showAdditionalProducts,
      toggleAdditionalProducts,
      errors,
      isElectricityMode,
    };
  },
});
