import {
  ref,
  watchEffect,
  Ref,
  onMounted,
  reactive,
} from "@vue/composition-api";
import { findLastIndex } from "lodash-es";

export interface FillIconByValueItemType {
  id: string;
  value: number;
}

export function useFillIconByValue(
  elementRef: Ref<null | HTMLElement>,
  items: FillIconByValueItemType[],
  currentValue: Ref<null | number>,
  // eslint-disable-next-line
  callback: (val: any) => void = (val: unknown = undefined) => val
) {
  const findIndex = () => {
    if (typeof currentValue.value !== "number" || currentValue.value === 0) {
      return -1;
    }

    return Math.max(
      0,
      findLastIndex(
        items,
        (item) => (currentValue.value as number) >= item.value
      )
    );
  };

  const lastFillIndex = ref(findIndex());
  const allElements = reactive(
    [] as { element: HTMLElement; defaultStyle: CSSStyleDeclaration }[]
  );

  onMounted(() => {
    if (elementRef.value) {
      items.forEach(({ id }, index) => {
        // eslint-disable-next-line
        const element = (elementRef.value as any).querySelector(`#${id}`);

        if (element) {
          const defaultStyle = { ...element.style };

          element.addEventListener("click", () => {
            currentValue.value = items[index].value;
            callback(items[index].value);
          });

          allElements.push({
            element,
            defaultStyle,
          });
        }
      });
    }
  });

  const stopFindIndex = watchEffect(() => {
    const index = findIndex();

    if (index !== lastFillIndex.value) {
      lastFillIndex.value = index;
    }
  });

  const stopFillIcon = watchEffect(() => {
    if (elementRef.value) {
      allElements.forEach(({ element, defaultStyle }, i) => {
        if (i <= lastFillIndex.value) {
          element.style.fill = "currentColor";
          element.style.stroke = "#e6e6e6";
          element.style.strokeWidth = "1.4";
        } else {
          element.style.fill = defaultStyle.fill || "";
          element.style.stroke = defaultStyle.stroke || "";
          element.style.strokeWidth = defaultStyle.strokeWidth || "";
        }
      });
    }
  });

  return {
    elementRef,
    lastFillIndex,
    stopFindIndex,
    stopFillIcon,
  };
}
