










import { defineComponent, PropType, computed } from "@vue/composition-api";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

import { SliderOptions } from "./InputSlider.types";

export default defineComponent({
  name: "InputSlider",
  props: {
    options: {
      type: Object as PropType<SliderOptions>,
      default: () => ({}),
    },
  },
  components: {
    VueSlider,
  },
  setup(props) {
    const sliderOptions = computed(
      () =>
        ({
          min: 0,
          dotSize: 22,
          contained: true,
          dragOnClick: true,
          adsorb: true,
          useKeyboard: true,
          tooltip: "none",
          dotOptions: {
            focusStyle: {
              boxShadow: "var(--outline-orange)",
            },
          },
          dotStyle: {
            backgroundColor: "var(--orange-500)",
            border: "none",
          },
          railStyle: {
            backgroundColor: "var(--orange-500)",
            height: "2px",
          },
          processStyle: {
            backgroundColor: "var(--orange-500)",
          },
          ...props.options,
        } as const)
    );

    return {
      sliderOptions,
    };
  },
});
