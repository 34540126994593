



























import { defineComponent, computed } from "@vue/composition-api";

import InfoPopup from "@/shared/components/InfoPopup";

import { BaseRadioButtonProps } from "@/shared/components/base/BaseRadioButton";

export default defineComponent({
  name: "TariffCalculatorBlockingTime",
  components: {
    InfoPopup,
  },
  setup(_, ctx) {
    const computedOptions = computed<BaseRadioButtonProps[]>(() => {
      const options: BaseRadioButtonProps[] = [
        {
          name: "isBlockingTime",
          value: 1,
          title: "Ja",
        },
        {
          name: "isBlockingTime",
          value: 0,
          title: "Nein",
        },
      ];

      options.forEach((option) => {
        option["checked"] = ctx.attrs.value == option.value;
      });

      return options;
    });

    return {
      options: computedOptions,
    };
  },
});
