





































































import { defineComponent, PropType } from "@vue/composition-api";

// import { HOST } from "@/constants";

import { FundingProjectItem } from "../types";

export default defineComponent({
  name: "FundingProjectDetails",
  props: {
    handleSelectProject: {
      type: Function as PropType<(project: FundingProjectItem) => void>,
      default: () => undefined,
    },
    project: {
      type: Object as PropType<FundingProjectItem>,
      required: true,
    },
  },
  setup({ handleSelectProject, project }, { root }) {
    function close() {
      root.$modal.hide(project.id);
    }

    function selectProject() {
      handleSelectProject(project);
      close();
    }

    return {
      close,
      selectProject,
      host: window.location.origin ?? 'https://www.evo-energie.de',
    };
  },
});
