

















































import {
  defineComponent,
  reactive,
  toRef,
  toRefs,
  PropType,
  onMounted,
  ref,
  computed,
} from "@vue/composition-api";

import { DivisionType, CustomerType, GetAddressesReturnType } from "@/api/sap";

import {
  useConsumptionSliderOptions,
  useInitTariffCalc,
  useTariffCalcSubmit,
} from "@/features/tariff-calculator/hooks";

import SapPostalCodeField from "@/features/shared/SapPostalCodeField";

import { tariffCalculatorConstants } from "@/features/tariff-calculator/constants";
import { TariffCalculatorConfig } from "@/features/tariff-calculator/components/TariffCalculator";
import TariffCalculatorConsumptionSlider from "@/features/tariff-calculator/components/TariffCalculatorConsumptionSlider";
import TariffCalculatorCustomerType from "@/features/tariff-calculator/components/TariffCalculatorCustomerType";
import TariffCalculatorForm from "@/features/tariff-calculator/components/TariffCalculatorForm";
import { pushEvent } from "@/helper/pushEvent.helper";

interface TariffCalculatorGasViewState {
  affiliateId?: string;
  calculatorMode: keyof typeof DivisionType;
  customerType: CustomerType;
  address: GetAddressesReturnType | undefined;
  gasConsumption: string | undefined;
  allowedProductIds?: string;
  serviceNo?: string;
}

export default defineComponent({
  name: "TariffCalculatorGasView",
  components: {
    SapPostalCodeField,
    TariffCalculatorConsumptionSlider,
    TariffCalculatorCustomerType,
    TariffCalculatorForm,
  },
  props: {
    config: {
      type: Object as PropType<TariffCalculatorConfig>,
      default: () => ({} as TariffCalculatorConfig),
    },
    initialValues: {
      type: Object as PropType<Partial<TariffCalculatorGasViewState>>,
      default: () => ({} as Partial<TariffCalculatorGasViewState>),
    },
    shouldRedirect: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const isSingleCustomerType = ref(false);
    const isCalculatorTypeMini = ref(false);

    onMounted(() => {
      if (props.config.customerType) {
        isSingleCustomerType.value = true;
      }

      if (props.config.calculatorMini) {
        isCalculatorTypeMini.value = props.config.calculatorMini;
      }
    });

    const defaultState: TariffCalculatorGasViewState = {
      calculatorMode: "Gas" as keyof typeof DivisionType,
      customerType: (props.config.customerType as keyof typeof CustomerType)
        ? CustomerType[props.config.customerType as keyof typeof CustomerType]
        : CustomerType.PrivateCustomer,
      address: undefined,
      gasConsumption: undefined,
      allowedProductIds: props.config.allowedProductIds,
      serviceNo: props.config.serviceNo
        ? props.config.serviceNo.gas
        : undefined,
    };

    const { getInitialStateValues } = useInitTariffCalc(
      defaultState,
      props.initialValues
    );

    const state = reactive<TariffCalculatorGasViewState>({
      ...defaultState,
      ...getInitialStateValues(),
    });

    const {
      sliderOptions,
      fillIconItems,
      iconName,
    } = useConsumptionSliderOptions({
      mode: toRef(state, "calculatorMode"),
      customerType: toRef(state, "customerType"),
    });

    const gasConsumptionLimit = computed(
      () => tariffCalculatorConstants.limits.Gas[state.customerType]
    );

    const customResultUrl = computed(() =>
      parseInt(state.gasConsumption ?? "") > gasConsumptionLimit.value
        ? props.config.resultURLService
        : props.config.resultURL
    );

    const { submitCalculator } = useTariffCalcSubmit(
      customResultUrl,
      props.shouldRedirect
    );

    function onSubmit() {
      pushEvent({
        event: "calculate",
        eventCategory: "Tarifrechner",
        eventAction: "Tarif berechnen",
        eventLabel: "Gas",
      });

      submitCalculator({ values: state });
    }

    return {
      ...toRefs(state),
      sliderOptions,
      fillIconItems,
      onSubmit,
      iconName,
      isSingleCustomerType,
      isCalculatorTypeMini,
      gasConsumptionLimit,
    };
  },
});
